.halfBanner{
	height: 50vh;
	min-height: auto;
}
.untree_co-hero.halfBanner, .untree_co-hero.halfBanner > .container > .row{
	height: 50vh;
	min-height: auto;
}
.feature{
	cursor: pointer;
	transition: .2s;
}
.feature.active{
	border: 1px solid #081b3e;
}
.feature.active h3{
	color: #081b3e;
}
.feature p span{
	font-size: 14px;
    padding-left: 4px;
    color: #E9703F;
}
.customContentModal h5{
	font-weight: 600;
	margin: 0;
}
@media only screen and (max-width: 1300px){
	.top-bar{
		padding: 4px 0;
	}
	.sticky-nav{
		padding: 10px 0;
	}
}
@media only screen and (max-width: 992px){
	.site-nav{
		padding-bottom: 20px;
	}
}